.App {
  text-align: center;
  /* max-width: 500px; */
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  text-align: left;
  font-size: 14px;
  text-align: justify;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  color: #fff;
  text-decoration: underline;
  font-size: 15px;
  font-weight: bold;
}

.close-icon:after {
  display: inline-block;
  content: "\00d7";
  padding: 0 10px 5px 10px;
  margin: 15px;
  font-size: 20px;
  position: absolute;
  right: 0;
  line-height: 25px;

}


.container-menu {
  margin: 0 auto;
}

.main-content {
  padding: 10px;
  min-height: 1000px;
  margin-top: 15px;
}

.nav {
  padding: 5px;
  background: #222;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 15px;
}

.nav ul {
  display: flex;
  list-style: none;
  justify-content: space-around;
  padding: 0;
  margin: 5px;
}

.footer {
  padding: 5px;
  margin-top: 15px;
}

span {
  color: #000;
}

.head-logo {
  width: 100%;
  padding: 20px 0px 10px;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.main-1>img {
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 0 auto;
}

.main-1>a>img {
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 0 auto;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.social-icons a {
  margin: 5px;
}

.card-main {
  border-radius: 10px;
  margin: 10px 0;
  background-color: #34373e;
}

.sector {
  font-weight: bold;
  color: #2dbf00;
  margin-right: 10px;
  font-size: 16px;
}


.course {
  font-weight: bold;
  margin-left: 10px;
  color: #2dbf00;
  font-size: 16px;
}

.desc {
  font-size: 15px;
}

.oncard {
  margin: 0;
}

.inst {
  font-style: italic;
  color: #f1f1f1;
}

.start {
  font-style: italic;
  color: #60f033;
}

.aboutus {
  background-color: #34373e;
  margin: 10px;
  padding: 10px;
  border-radius: 20px;


}

.aboutus p {
  line-height: 30px;
  font-size: 16px;
}

h3 {
  margin: 28px 0 0 0;
}